import * as React from 'react'
import { useRouter } from 'next/router'
import { FaFacebookF, FaYoutube, FaTwitter, FaPinterest } from 'react-icons/fa'
import { AiOutlineInstagram } from 'react-icons/ai'
import { PageLink } from '../../components/PageLink'
import { Heading } from '../../components/Text'
import { useShopData } from '../../providers/ShopDataProvider'
import { NewsletterSignup } from './NewsletterSignup'
import JAUCircle from '../../svg/JAUCircle.svg'
import LogoLessPlastic from '../../svg/LogoLessPlastic.svg'
import {
  Socials,
  FooterWrapper,
  FooterInner,
  FooterInnerLower,
  FooterLinks,
  LogoWrapper,
} from './styled'
import { Box } from '@xstyled/styled-components'

const currentYear = new Date().getFullYear()

export const Footer = () => {
  const router = useRouter()
  const shopData = useShopData()
  const footerLinks = shopData?.siteSettings?.links ?? []
  const mailerTitle = shopData?.siteSettings?.mailerTitle ?? ''
  const mailerSubtitle = shopData?.siteSettings?.mailerSubtitle ?? ''

  const topBorder = /(^\/925)|(^\/about)|(^\/products)/.test(router.asPath)

  return (
    <FooterWrapper>
      <FooterInner topBorder={topBorder}>
        {/* <FooterLinks>
          {footerLinks.map((link) =>
            link ? (
              <Heading m={0} key={link._key || 'some-key'} level={4}>
                <PageLink link={link} />
              </Heading>
            ) : null,
          )}
        </FooterLinks> */}
        <div />
        {/* <NewsletterSignup
          mailerTitle={mailerTitle}
          mailerSubtitle={mailerSubtitle}
        /> */}
      </FooterInner>
      <FooterInnerLower>
        <Socials>
          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.co"
          >
            <FaFacebookF />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/"
          >
            <FaTwitter />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.pinterest.com/"
          >
            <FaPinterest />
          </a>*/}

          <a target="_blank" rel="noopener noreferrer" href="https://jau.co.jp">
            <LogoWrapper width="30px">
              <JAUCircle />
            </LogoWrapper>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://lessplastic.jp"
          >
            <LogoWrapper width="100px">
              <LogoLessPlastic />
            </LogoWrapper>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/lessplastic.jp"
          >
            <AiOutlineInstagram />
          </a>
        </Socials>
        <div />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://longforme.com"
        >
          <Heading m={0} level={5} textAlign="right">
            © {currentYear} 合同会社Long Forme KeepCup 日本正規輸入販売代理店
          </Heading>
        </a>
      </FooterInnerLower>
    </FooterWrapper>
  )
}
