import * as React from 'react'
import Link from 'next/link'
import styled, { css } from '@xstyled/styled-components'
import { NextRouter } from 'next/router'
import { unwindEdges } from '@good-idea/unwind-edges'
import { useShopify } from '../../providers/ShopifyProvider'
import { useNavigation } from '../../providers/NavigationProvider'
import { Heading } from '../../components/Text'
import { IoBagOutline } from 'react-icons/io5'
import Logo from '../../svg/LogoJAUV2.svg'
import { Checkout } from '../Cart/Checkout'
import { Hamburger } from '../Hamburger'
import {
  Wrapper,
  Inner,
  CartBadge,
  CartButtonWrapper,
  SideNavigation,
  LogoWrapper,
  NavInnerBackground,
  ToolsWrapper,
} from './styled'
import { NavigationInner } from './NavigationInner'

export const Navigation = () => {
  const {
    closeAll,
    cartOpen,
    menuOpen,
    closeMenu,
    toggleMenu,
    openCart,
    router,
  } = useNavigation()
  /* Parsing */
  const { loading, checkout } = useShopify()
  const lineItems = checkout ? unwindEdges(checkout.lineItems)[0] : []
  const cartCount = loading ? 0 : lineItems.length || 0

  const openCartHandler = () => openCart()

  const innerBorder = !/\/collections/.test(router.asPath)
  const Nav = styled.nav`
    ${({ theme }) => css`
      position: sticky;
      z-index: ${theme.zIndices.nav};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      & > * {
        pointer-events: auto;
      }
    `}
  `
  return (
    <>
      <NavInnerBackground
        onClick={closeAll}
        aria-hidden={!cartOpen && !menuOpen}
        open={menuOpen || cartOpen}
      />
      <SideNavigation open={menuOpen}>
        <NavigationInner closeMenu={closeMenu} />
      </SideNavigation>

      <Wrapper>
        <Inner withBorder={innerBorder}>
          <LogoWrapper>
            <Link href="/" as="/">
              <a>
                <Logo />
              </a>
            </Link>
          </LogoWrapper>

          <ToolsWrapper>
            <CartButtonWrapper isLoading={loading} onClick={openCartHandler}>
              {cartCount ? (
                <CartBadge>
                  <Heading m={0} fontWeight={4} level={5}>
                    {cartCount}
                  </Heading>
                </CartBadge>
              ) : null}
              <IoBagOutline size={18} />
            </CartButtonWrapper>
            <Hamburger onClick={toggleMenu} open={false} />
          </ToolsWrapper>
        </Inner>
      </Wrapper>
      <Checkout />
    </>
  )
}

interface NavigationProps {
  router: NextRouter
}
