import { useEffect } from 'react'
import { useRouter } from 'next/router'

const handleRouteChange = () => {
  pageview()
}

const pageview = () => {
  ;(window as any).fbq && (window as any).fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
const event = (name, options = {}) => {
  ;(window as any).fbq && (window as any).fbq('track', name, options)
}

export const FacebookPixelScript = ({ pixel }) => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${pixel});
      `,
      }}
    />
  )
}

export const FacebookPixel = ({ children }) => {
  const router = useRouter()

  useEffect(() => {
    // This pageview only trigger first time (it is important for Pixel to have real information)
    pageview()

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return children
}
