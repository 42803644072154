import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { config } from '../../config'

const handleRouteChange = () => {
  pageview({ url: window.location.href })
}

const pageview = ({ url }) => {
  if (!(window as any).gtag) {
    return
  }

  ;(window as any).gtag('config', config.GA_TRACKING_ID, {
    page_location: url,
  })
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
const event = (name, options = {}) => {
  ;(window as any).fbq('track', name, options)
}

export const GoogleTagManagerScripts = () => {
  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.GA_TRACKING_ID}');
          `,
        }}
      />
    </>
  )
}

export const GoogleTagManager = ({ children }) => {
  const router = useRouter()

  useEffect(() => {
    // This pageview only trigger first time
    handleRouteChange()

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return children
}
