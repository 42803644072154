import * as React from 'react'
import { NextRouter } from 'next/router'
import styled, { ThemeProvider } from '@xstyled/styled-components'
import Head from 'next/head'
import { Providers } from '../src/providers/AllProviders'
import { Footer } from '../src/components/Footer'
import { Navigation } from '../src/components/Navigation'
// import  { SearchPane } from '../src/components/Search'
import { getThemeByRoute } from '../src/theme'
import { config } from '../src/config'
import { Announcement } from '../src/components/Announcement'
import {
  FacebookPixel,
  FacebookPixelScript,
} from '../src/providers/FacebookPixel'
import {
  GoogleTagManager,
  GoogleTagManagerScripts,
} from '../src/providers/GoogleTagManager/GoogleTagManager'

interface AppProps {
  Component: React.ComponentType
  pageProps: any
  router: NextRouter
}

const Main = styled.main`
  background-color: background;
  transition: background-color 0.3s;
`

const { STOREFRONT_ENV } = config

const App = (props: AppProps) => {
  const { Component, pageProps: allPageProps, router } = props
  const path = router.asPath
  const { shopData, ...pageProps } = allPageProps
  if (!shopData) return null
  // const ENV = process.env.STOREFRONT_ENV
  // const tagInfo =
  //   ENV === 'production' ? gtm.prod : ENV === 'staging' ? gtm.staging : gtm.dev

  return (
    <Providers shopData={shopData}>
      <ThemeProvider theme={getThemeByRoute(path)}>
        <Head>
          {/* Tag Manager */}
          {/* <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: tagInfo.script,
            }}
          /> */}

          <GoogleTagManagerScripts />

          <link rel="stylesheet" href="/static/fonts/fonts.css" />
          <link rel="icon" href="/static/favicon.png" />
          <link
            rel="shortcut icon"
            type="image/x-icon"
            href="/static/favicon/favicon.ico"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
          />
          {/*  Pinterest tag  */}
          {/* <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
                !function(e){if(!window.pintrk){window.pintrk=function()
                {window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
                n=window.pintrk;n.queue=[],n.version="3.0";var
                t=document.createElement("script");t.async=!0,t.src=e;var
                r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}
                ("https://s.pinimg.com/ct/core.js");
                pintrk('load','2613624654029', { em: '', });
                pintrk('page');
             `,
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
                <img
                  height="1"
                  width="1"
                  style={{ display: 'none' }}
                  alt=""
                  src="https://ct.pinterest.com/v3/?tid=2613624654029&noscript=1"
                />
              `,
            }}
          /> */}
          <link
            href="https://fonts.googleapis.com/css?family=Playfair+Display:400|Palanquin:300,700"
            rel="stylesheet"
          />

          {/* Global Site Code Pixel - Facebook Pixel */}
          <FacebookPixelScript pixel={config.FB_PIXEL} />
        </Head>
        <Main>
          <FacebookPixel>
            <GoogleTagManager>
              <Navigation />
              {/* <SearchPane /> */}
              <Component {...pageProps} />
              <Footer />
              <Announcement />
            </GoogleTagManager>
          </FacebookPixel>
        </Main>
        <div id="modal" />
      </ThemeProvider>
      <script
        /* Hubspot */
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src={`//js.hs-scripts.com/${config.HUBSPOT_PORTAL_ID}.js`}
      />
    </Providers>
  )
}

export default App
